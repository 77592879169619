<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div class="sign-in">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">
			<a-col :span="24" :md="12" :lg="{span: 12, offset: 0}" :xl="{span: 6, offset: 2}" class="col-form">
				<h1 class="mb-15">登 陆</h1>
				<h5 class="font-regular text-muted">请输入您的邮箱进行登陆</h5>
				<a-form
					id="components-form-demo-normal-login"
					:form="form"
					class="login-form"
					@submit="handleSubmit"
					:hideRequiredMark="true"
				>
					<a-form-item class="mb-10" label="企业邮箱" :colon="false">
						<a-input 
						v-decorator="[
						'email',
						{ rules: [{ required: true, message: '请输入您的企业邮箱' }] },
						]" placeholder="Email" />
					</a-form-item>
					<a-form-item class="mb-5" label="密码" :colon="false">
						<a-input
						v-decorator="[
						'password',
						{ rules: [{ required: true, message: '请输入您的密码' }] },
						]" type="password" placeholder="Password" />
					</a-form-item>
					<a-form-item>
						<a-alert type="error" :style="{display:error_dis}"  message="账号密码不正确" banner />

						<a-button type="primary" block html-type="submit" class="login-form-button">
							登 陆
						</a-button>
						<p class="font-semibold text-muted text-center">忘记密码? 
							<a href="http://user.winndoo.cn/login" target="_blank">
								点击前往更改密码
							</a>
						</p>
					</a-form-item>
				</a-form>
			</a-col>
			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
				<img src="images/signin.jpg" alt="">
			</a-col>
		</a-row>
		
	</div>
</template>

<script>
	import axios from 'axios'

	export default ({
		data() {
			return {
				error_dis:'none',
				form: this.$form.createForm(this),
			}
		},
		methods: {
			handleSubmit(e) {
				let _that = this
				_that.form.validateFields((err, values) => {
					e.preventDefault();
					if ( !err ) {
						console.log('Received values of form: ', values) ;
						axios.post(
							'http://user.winndoo.cn/api/user/login', 
							values,
						).then(response => {
							if(response.data.code == 0){
								_that.error_dis = 'block'
							}else{
								_that.error_dis = 'none'
								response.data.data.startTime = new Date().getTime()
								response.data.data.expire = 60*60*3*1000
								localStorage.setItem('user_info',JSON.stringify(response.data.data));
								return _that.$router.push({ name:'Dashboard' })
							}
						}).catch(error => {
							_that.error_dis = 'block'
						})
					}
				});
			},
		},
		mounted() {
			let user_info = localStorage.getItem('user_info')
			let _that = this
			if(user_info){
				return _that.$router.push({ name:'Dashboard' })
			}
		},
	})

</script>

<style lang="scss">
	body {
		background-color: #ffffff;
	}
</style>